import {
  faEnvelope,
  faLock,
  faPaperPlane,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { useState } from "react";
import { Button, FormGroup, Label } from "reactstrap";
import { fetchResetPassword } from "../../services/services";
import { validatePassword, validateVerificationCode } from "../../utils";
import { CustomErrorField } from "../components/CustomErrorField";

interface Param {
  forgotPassword: {
    show: boolean;
    email: string;
    msg: string;
  };
}

interface Error {
  email?: string;
  password: string;
  password2: string;
  verification: string;
}

interface Values {
  email?: string;
  password: string;
  password2: string;
  verification: string;
}

export const NewPassword = ({ forgotPassword }: Param) => {
  const { email /* , msg */ } = forgotPassword;

  const [msg, setMsg] = useState("");
  const [msgErr, setMsgErr] = useState("");
  const [isChanged, setIsChanged] = useState(false);

  return (
    <div>
      <div>
        <div className="alert alert-info">
          <i className="fas fa-info-circle right5" />
          We have sent you the verification code to your email account
        </div>
      </div>
      <Formik
        initialValues={{
          email,
          password: "",
          password2: "",
          verification: "",
        }}
        validate={(values) => {
          let errors: Error = {};

          if (!values.password) {
            errors.password = "This field is required";
          } else if (validatePassword(values.password)) {
            errors.password =
              "Please, enter a valid password. Minimum 8 characters, must contain 1 number, 1 lowercase, 1 uppercase and 1 symbol (!@#$&*).";
          }

          if (!values.password2) {
            errors.password2 = "This field is required";
          } else if (validatePassword(values.password2)) {
            errors.password2 = "Please, enter a valid password";
          }

          if (
            values.password &&
            values.password2 &&
            values.password !== values.password2
          ) {
            errors.password2 = "Password confirmation doesn't match";
          }

          if (!values.verification) {
            errors.verification = "This field is required";
          } else if (validateVerificationCode(values.verification)) {
            errors.verification = "Please, enter a valid code";
          }

          return errors;
        }}
        onSubmit={async (
          values: Values,
          { setSubmitting }: FormikHelpers<Values>
        ) => {
          let body = {
            code: values.verification,
            email: values.email,
            password: values.password,
          };

          const { status, message } = await fetchResetPassword(body);

          if (status === "Success") {
            setMsg(message);
            setMsgErr("");
            setIsChanged(true);
          } else {
            setMsgErr(message);
          }
        }}
      >
        {({ isSubmitting }) => (
          <Form className="form-formik">
            <Label htmlFor="email">
              <FontAwesomeIcon
                id="PinTooltip"
                icon={faEnvelope}
                color="#344675"
              />{" "}
              Email
            </Label>
            <FormGroup>
              <Field
                id="email"
                name="email"
                type="email"
                placeholder="Your email"
                className="form-control"
                disabled
              />
            </FormGroup>
            <ErrorMessage name="email" component={CustomErrorField} />
            <Label htmlFor="password">
              <FontAwesomeIcon id="PinTooltip" icon={faLock} color="#344675" />{" "}
              New password
            </Label>
            <FormGroup>
              <Field
                id="password"
                name="password"
                placeholder="New password"
                className="form-control"
                type="password"
              />
            </FormGroup>
            <ErrorMessage name="password" component={CustomErrorField} />
            <Label htmlFor="password2">
              <FontAwesomeIcon id="PinTooltip" icon={faLock} color="#344675" />{" "}
              Confirm password
            </Label>
            <FormGroup>
              <Field
                id="password2"
                name="password2"
                placeholder="Confirm password"
                className="form-control"
                type="password"
              />
            </FormGroup>
            <ErrorMessage name="password2" component={CustomErrorField} />
            <Label htmlFor="verification">
              <FontAwesomeIcon
                id="PinTooltip"
                icon={faPaperPlane}
                color="#344675"
              />{" "}
              Verification code
            </Label>
            <FormGroup>
              <Field
                id="verification"
                name="verification"
                placeholder="Verification code"
                className="form-control"
                type="text"
              />
            </FormGroup>
            <ErrorMessage name="verification" component={CustomErrorField} />
            <Button
              className="btn-fill btn-forgot"
              color="primary"
              type="submit"
              disabled={isSubmitting || isChanged}
            >
              Reset password
            </Button>
          </Form>
        )}
      </Formik>
      <div className="text-center top10">
        {msgErr === "" && <p className="reset-info-success">{msg}</p>}
        {msgErr !== "" && <p>{msgErr}</p>}
      </div>
    </div>
  );
};
