import {
  URL_COUNTERS,
  URL_CHANGE_PASSWORD,
  URL_FORGOT, URL_GLOBAL_SIGN_OUT, URL_MFA_GENERATE,
  URL_MFA_PREFERENCE,
  URL_MFA_STATUS,
  URL_MFA_VERIFY,
  URL_PROFILE,
  URL_PUT_WEBHOOK,
  URL_RESET_PASSWORD,
  URL_REVOKE_TOKEN,
  URL_RE_VERIFY_ACCOUNT,
  URL_ADMIN_USERS_LIST,
  URL_SECRETS,
  URL_SECRETS_LIST,
  URL_SECRET_CREATE_DEFAULT,
  URL_SECRET_DETAIL,
  URL_SECRET_UPDATE,
  URL_SET_DEFAULT_TEMPLATES,
  URL_SET_DEFAULT_TEMPLATES_RECEIVE,
  URL_SET_SUBSCRIPTION,
  URL_SET_WELCOMESCREEN,
  URL_SUBSCRIPTIONS,
  URL_SUBSCRIPTIONS_AUTH,
  URL_SUBSCRIPTION_LINK,
  URL_SUBSCRIPTION_PORTAL,
  URL_TEMPLATES,
  URL_TEMPLATES_ALL,
  URL_TEMPLATES_RECEIVE,
  URL_TEMPLATES_RECEIVE_ALL,
  URL_UPDATE_FULL_NAME,
  URL_USER_DETAILS,
  URL_USER_SETTINGS,
  URL_WEBAUTHN_DELETE,
  URL_ADMIN_USER_STATUS,
  URL_SECRET_FILE_UPLOAD,
  URL_SECRET_SCHEMAS,
} from "../constants/urls";
import { isLoggedIn } from "./common";

export const fetchGlobalSignOut = async () => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_GLOBAL_SIGN_OUT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log("Error when trying to do a global sign out", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchUserProfile = async (lastToken) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged || lastToken) {
    const token = localStorage.getItem("token") || lastToken;

    return await fetch(URL_PROFILE, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
    })
      .then((response) => {
        if (response.status === 401 || response.status === 400) return { redirect: true };
        else {
          return response.json();
        }
      })
      .then((res) => res)
      .catch((error) => {
        console.log("Error when trying to get a profile information", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchSecretSchemas = async () => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");

    return await fetch(URL_SECRET_SCHEMAS, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
    })
      .then((response) => {
        if (response.status === 401 || response.status === 400) return { redirect: true };
        else {
          return response.json();
        }
      })
      .then((res) => res)
      .catch((error) => {
        console.log("Error when trying to get secret schemas", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchForgotPassword = async (body) => {
  return await fetch(URL_FORGOT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((error) => {
      console.log(
        "Error when trying to start recovery password process",
        error
      );
      return error;
    });
};

export const fetchResendVerifyAccount = async (body) => {
  return await fetch(URL_RE_VERIFY_ACCOUNT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((error) => {
      console.log("Error when trying to send a new validation token", error);
      return error;
    });
};

export const fetchResetPassword = async (body) => {
  return await fetch(URL_RESET_PASSWORD, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(body),
  })
    .then((res) => res.json())
    .then((data) => data)
    .catch((error) => {
      console.log("Error when trying to change the password", error);
      return { message: error };
    });
};

export const fetchRevokeToken = async () => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    const refreshToken = localStorage.getItem("refreshToken");
    return await fetch(URL_REVOKE_TOKEN, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify({
        refreshToken: refreshToken,
      }),
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log("Error when trying to get a profile information", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchUpdateName = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    try {
      const res = await fetch(URL_UPDATE_FULL_NAME, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Auth: newToken === "" ? token : newToken,
        },
        body: JSON.stringify(body),
      });

      const responseJSON = await res.json();
      return responseJSON;
    } catch (error) {
      console.log("Error when trying to get a profile information", error);
      return error;
    }
  } else {
    return { redirect: true };
  }
};

export const fetchUserStatusUpdate = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    try {
      const res = await fetch(URL_ADMIN_USER_STATUS, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Auth: newToken === "" ? token : newToken,
        },
        body: JSON.stringify(body),
      });

      const responseJSON = await res.json();
      return responseJSON;
    } catch (error) {
      console.log("Error when trying to get a profile information", error);
      return error;
    }
  } else {
    return { redirect: true };
  }
};

export const fetchMFAStatus = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_MFA_STATUS, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => res)
      .catch((error) => {
        console.log("Error when trying to get MFA status", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchMFAPreference = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_MFA_PREFERENCE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },

      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => res)
      .catch((error) => {
        console.log("Error when trying to generate MFA code", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchMFAGenerate = async () => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_MFA_GENERATE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
    })
      .then((response) => response.json())
      .then((res) => res)
      .catch((error) => {
        console.log("Error when trying to generate MFA code", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchMFAVerify = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_MFA_VERIFY, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => res)
      .catch((error) => {
        console.log("Error when trying to verify MFA", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchChangePassword = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_CHANGE_PASSWORD, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => res)
      .catch((error) => {
        console.log("Error when trying to get a profile information", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchPutWelcomescreen = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_SET_WELCOMESCREEN, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => res)
      .catch((error) => {
        console.log("Error when trying to update your profile", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchPutWebhook = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_PUT_WEBHOOK, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => res)
      .catch((error) => {
        console.log("Error when trying to get a profile information", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchDeleteWebhook = async () => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_PUT_WEBHOOK, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
    })
      .then((response) => response.json())
      .then((res) => res)
      .catch((error) => {
        console.log("Error when trying to get a profile information", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchDeleteSecurityKey = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_WEBAUTHN_DELETE, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => res)
      .catch((error) => {
        console.log("Error when trying to get a profile information", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchUserDetails = async () => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return fetch(URL_USER_DETAILS, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
    })
      .then((response) => response.json())
      .then((res) => {
        return res;
      })
      .catch((error) => {
        console.log("Error when trying to get a profile information", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchSetSubscription = async ({ subscriptionId }) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_SET_SUBSCRIPTION, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify({
        subscriptionId: subscriptionId,
      }),
    })
      .then((response) => response.json())
      .then((res) => res)
      .catch((error) => {
        console.log("Error when trying to get a profile information", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchPlans = async () => {
  return await fetch(URL_SUBSCRIPTIONS, {
    method: "GET",
    headers: {
      "Content-Type": "application/json",
    },
  })
    .then((response) => response.json())
    .then((res) => res)
    .catch((error) => {
      console.log("Error when trying to get a profile information", error);
      return error;
    });
};

export const fetchPlansAuth = async () => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_SUBSCRIPTIONS_AUTH, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
    })
      .then((response) => response.json())
      .then((res) => res)
      .catch((error) => {
        console.log("Error when trying to get a profile information", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchPlanLink = async (id) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(`${URL_SUBSCRIPTION_LINK}?plan=${id}`, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
    })
      .then((response) => response.json())
      .then((res) => res)
      .catch((error) => {
        console.log("Error when trying to get a profile information", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchPortalLink = async () => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_SUBSCRIPTION_PORTAL, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
    })
      .then((response) => response.json())
      .then((res) => res)
      .catch((error) => {
        console.log("Error when trying to get a profile information", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchGlobalCounters = async () => {
  const { isLogged } = await isLoggedIn();

  if (isLogged) {
    return await fetch(URL_COUNTERS, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then((response) => response.json())
      .then((res) => res)
      .catch((error) => {
        console.log("Error when trying to get a profile information", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchNewSecret = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    if (body.otl) {
      body.lockAfterUse = false;
    }

    const token = localStorage.getItem("token");
    return await fetch(URL_SECRETS, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error when trying to create a secret", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchNewSecretDefaultTemplate = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    if (body.otl) {
      body.lockAfterUse = false;
    }

    const token = localStorage.getItem("token");
    return await fetch(URL_SECRET_CREATE_DEFAULT, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error when trying to create a secret", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};
/*
export const fetchUsers = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    let queryString = "?";
    let urlRequest = URL_ADMIN_USERS_LIST;
    let queryAssigns = [];

    if(body.paginationToken){
      queryAssigns.push(`paginationToken=${encodeURIComponent(body.paginationToken)}`);
    }

    if(body.email){
      queryAssigns.push(`email=${encodeURIComponent(body.email)}`);
    }

  
    queryString += (queryAssigns.length ? queryAssigns.join("&") : "");

    if(queryString != "?"){
      urlRequest += queryString;
    }

    const token = localStorage.getItem("token");
    return await fetch(urlRequest, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
    })
      .then((res) => res.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error when trying to get users", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};*/

export const fetchUsers = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_ADMIN_USERS_LIST, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error when trying to get secrets", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchSecrets = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {

    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    if(user.purposeMode){
      body.purpose = "receive";
    }else{
      body.purpose = "send";
    }

    return await fetch(URL_SECRETS_LIST, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error when trying to get secrets", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchSecretDetail = async (secretId, content = false) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");

    let urlFetch =`${URL_SECRET_DETAIL}?secretId=${secretId}`;

    if(content){
      urlFetch += '&content=1';
    }

    return await fetch(urlFetch, {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
    })
      .then((res) => res.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error when trying to get secret details", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchSecretUpdate = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    if (body.otl) {
      body.lockAfterUse = false;
    }

    const token = localStorage.getItem("token");
    return await fetch(URL_SECRET_UPDATE, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error when trying to update a secret", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchNewTemplate = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    if (body.details.otl) {
      body.details.lockAfterUse = false;
    }

    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    return await fetch(user.purposeMode? URL_TEMPLATES_RECEIVE: URL_TEMPLATES, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error when trying to update a secret", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchSetDefaultTemplate = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    if (body.otl) {
      body.lockAfterUse = false;
    }
    
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    return await fetch(user.purposeMode ? URL_SET_DEFAULT_TEMPLATES_RECEIVE : URL_SET_DEFAULT_TEMPLATES, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error when trying to update a secret", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchSecretDelete = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_SECRETS, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error when trying to delete a secret", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchTemplates = async (global = false) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    let headers = {
      "Content-Type": "application/json",
      Auth: newToken === "" ? token : newToken,
    };

    if(global){
      headers["global"] = true;
    }

    return await fetch(user.purposeMode? URL_TEMPLATES_RECEIVE_ALL: URL_TEMPLATES_ALL, {
      method: "GET",
      headers: headers,
    })
      .then((res) => res.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error when trying to get templates", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchUpdateTemplate = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    if (
      body.updateAttributes &&
      body.updateAttributes.details &&
      body.updateAttributes.details.otl
    ) {
      body.updateAttributes.details.lockAfterUse = false;
    }
    
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    return await fetch(user.purposeMode? URL_TEMPLATES_RECEIVE: URL_TEMPLATES, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error when trying to delete a template", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchDeleteTemplate = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    
    const user = JSON.parse(localStorage.getItem("user"));
    const token = localStorage.getItem("token");

    return await fetch(user.purposeMode? URL_TEMPLATES_RECEIVE: URL_TEMPLATES, {
      method: "DELETE",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((res) => res.json())
      .then((data) => data)
      .catch((error) => {
        console.log("Error when trying to delete a template", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchUpdateGeneralSettings = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_USER_SETTINGS, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => res)
      .catch((error) => {
        console.log("Error when trying to update a profile information", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchFileUploadURL = async (body) => {
  const { isLogged, newToken } = await isLoggedIn();

  if (isLogged) {
    const token = localStorage.getItem("token");
    return await fetch(URL_SECRET_FILE_UPLOAD, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Auth: newToken === "" ? token : newToken,
      },
      body: JSON.stringify(body),
    })
      .then((response) => response.json())
      .then((res) => res)
      .catch((error) => {
        console.log("Error when trying to generate a file upload url", error);
        return error;
      });
  } else {
    return { redirect: true };
  }
};

export const fetchUploadFileToS3 = async (params, fileContent) => {
console.log("PARAMS", params);
  let formData = new FormData();
  
  for (let key in params.fields) {
    formData.append(key, params.fields[key]);
  }

  formData.append('file', fileContent);

  console.log(params, formData);
  let response = await fetch(params.url,
    {
        body: formData,
        method: "post"
    }
  );

  console.log(response);
  return response;
}