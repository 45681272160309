import FrontendConfig from "../assets/js/config";

const URL_BASE = FrontendConfig.api_endpoint;
export const URL_APP = FrontendConfig.current_url;
export const URL_VIEW = FrontendConfig.site_url;
export const URL_COUNTERS = `${URL_BASE}/counters`;
export const URL_SIGN_UP = `${URL_BASE}/user/signup`;
export const URL_SIGN_IN = `${URL_BASE}/user/signin`;
export const URL_SIGN_IN_MFA = `${URL_BASE}/user/signin/mfa`;
export const URL_SIGN_IN_SSO = `${URL_BASE}/user/signin/sso`;
export const URL_SIGN_IN_WEBAUTHN = `${URL_BASE}/user/signin/webauthn`;
export const URL_PROFILE = `${URL_BASE}/user/profile`;
export const URL_FORGOT = `${URL_BASE}/user/forgotpassword`;
export const URL_USER_DETAILS = `${URL_BASE}/user/details`;
export const URL_UPDATE_FULL_NAME = `${URL_BASE}/user/updatefullname`;
export const URL_CHANGE_PASSWORD = `${URL_BASE}/user/changepassword`;
export const URL_USER_REFRESH_TOKEN = `${URL_BASE}/user/refreshtoken`;
export const URL_SECRET_FILE_UPLOAD = `${URL_BASE}/secret/mysecrets/upload`;
export const URL_RE_VERIFY_ACCOUNT = `${URL_BASE}/user/reverifyaccount`;
export const URL_RESET_PASSWORD = `${URL_BASE}/user/confirmforgotpassword`;
export const URL_REVOKE_TOKEN = `${URL_BASE}/user/revoketoken`;
export const URL_GLOBAL_SIGN_OUT = `${URL_BASE}/user/globalsignout`;
export const URL_SUBSCRIPTIONS = `${URL_BASE}/subscriptions`;
export const URL_SUBSCRIPTION_LINK = `${URL_BASE}/user/subscription/link`;
export const URL_SET_SUBSCRIPTION = `${URL_BASE}/user/subscription`;
export const URL_SUBSCRIPTIONS_AUTH = `${URL_BASE}/user/subscription`;
export const URL_SUBSCRIPTION_PORTAL = `${URL_BASE}/user/subscription/portal`;
export const URL_USER_SETTINGS = `${URL_BASE}/user/settings`;
export const URL_MFA_STATUS = `${URL_BASE}/user/settings/mfa/status`;
export const URL_MFA_VERIFY = `${URL_BASE}/user/settings/mfa/verify`;
export const URL_MFA_GENERATE = `${URL_BASE}/user/settings/mfa/generate`;
export const URL_MFA_PREFERENCE = `${URL_BASE}/user/settings/mfa/preference`;
export const URL_PUT_WEBHOOK = `${URL_BASE}/user/settings/webhooks`;
export const URL_TEMPLATES = `${URL_BASE}/user/settings/secrettemplate`;
export const URL_TEMPLATES_ALL = `${URL_BASE}/user/settings/secrettemplate/mytemplates`;
export const URL_SET_DEFAULT_TEMPLATES = `${URL_BASE}/user/settings/secrettemplate/default`;
export const URL_TEMPLATES_RECEIVE = `${URL_BASE}/user/settings/secrettemplatereceive`;
export const URL_TEMPLATES_RECEIVE_ALL = `${URL_BASE}/user/settings/secrettemplatereceive/mytemplates`;
export const URL_SET_DEFAULT_TEMPLATES_RECEIVE = `${URL_BASE}/user/settings/secrettemplatereceive/default`;
export const URL_LABEL = `${URL_BASE}/user/settings/label`;
export const URL_SET_WELCOMESCREEN = `${URL_BASE}/user/settings/welcomescreen`;
export const URL_SECRETS = `${URL_BASE}/secret/mysecrets`;
export const URL_SECRETS_LIST = `${URL_BASE}/secret/mysecrets/search`;
export const URL_SECRET_DETAIL = `${URL_BASE}/secret/mysecrets/details`;
export const URL_SECRET_UPDATE = `${URL_BASE}/secret/mysecrets/update`;
export const URL_SECRET_CREATE_DEFAULT = `${URL_BASE}/secret/mysecrets/default`;
export const URL_SECRET_WEBAUTHN_LIST = `${URL_BASE}/secret/mysecrets/key/list`;
export const URL_SECRET_WEBAUTHN_CREATE = `${URL_BASE}/secret/mysecrets/key/create`;
export const URL_SECRET_WEBAUTHN_PARSE = `${URL_BASE}/secret/mysecrets/key/parse`;
export const URL_SECRET_WEBAUTHN_DELETE = `${URL_BASE}/secret/mysecrets/key/delete`;
export const URL_SECRET_WEBAUTHN_CHECK = `${URL_BASE}/secret/mysecrets/key/check`;
export const URL_SECRET_WEBAUTHN_CANCEL = `${URL_BASE}/secret/mysecrets/key/cancel`;
export const URL_SECRET_WEBAUTHN_EXISTENCE = `${URL_BASE}/secret/mysecrets/key/existence`;
export const URL_SECRET_WEBAUTHN_REMOTE_ADD = `${URL_BASE}/secret/mysecrets/key/remote`;
export const URL_WEBAUTHN_CREATE = `${URL_BASE}/webauthn/create`;
export const URL_WEBAUTHN_DELETE = `${URL_BASE}/webauthn/delete`;
export const URL_WEBAUTHN_PARSE = `${URL_BASE}/webauthn/parse`;
export const URL_WEBAUTHN_ENROLL_CREATE = `${URL_BASE}/webauthn/enroll-create`;
export const URL_WEBAUTHN_ENROLL_PARSE = `${URL_BASE}/webauthn/enroll-parse`;
export const URL_SEND_ENVELOPE = `${URL_BASE}/secret/send`;
export const URL_SECRET_SCHEMAS = `${URL_BASE}/secret/schemas`

/**
 * BEGIN: Admin routes
 */

export const URL_ADMIN_USERS_LIST = `${URL_BASE}/admin/users/list`;
export const URL_ADMIN_USER_STATUS = `${URL_BASE}/admin/users/status`;
export const URL_ADMIN_TEMPLATES_LIST = `${URL_BASE}/admin/secrettemplate/mytemplates`;
export const URL_ADMIN_TEMPLATES = `${URL_BASE}/admin/secrettemplate`;
export const URL_ADMIN_TEMPLATES_RECEIVE_LIST = `${URL_BASE}/admin/secrettemplatereceive/mytemplates`;
export const URL_ADMIN_TEMPLATES_RECEIVE = `${URL_BASE}/admin/secrettemplatereceive`;
export const URL_ADMIN_LABEL_LIST = `${URL_BASE}/admin/label/list`;
export const URL_ADMIN_LABEL = `${URL_BASE}/admin/label`;
export const URL_ADMIN_SECURITY_KEY_CREATE = `${URL_BASE}/admin/securitykeys/create`;
export const URL_ADMIN_SECURITY_KEY_PARSE = `${URL_BASE}/admin/securitykeys/parse`;
export const URL_ADMIN_SECURITY_KEY_DELETE = `${URL_BASE}/admin/securitykeys/delete`;
export const URL_ADMIN_SECURITY_KEY_LIST = `${URL_BASE}/admin/securitykeys/list`;
/**
 * END: Admin routes
 */