import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage, Field, Form, Formik, FormikHelpers } from "formik";
import { useCallback, useEffect, useState } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Button, FormGroup, Label } from "reactstrap";
import { fetchForgotPassword } from "../../services/services";
import { validateEmail } from "../../utils";
import { CustomErrorField } from "../components/CustomErrorField";

interface Values {
  email: string;
}

interface Error {
  email?: string;
}

export const ForgotPassword = ({ setForgotPassword }: any) => {
  const [msgErr, setMsgErr] = useState("");

  /**
   * reCaptcha code
   */
  const { executeRecaptcha } = useGoogleReCaptcha();

  // Create an event handler so you can call the verification on button click event or form submit
  const handleReCaptchaVerify = useCallback(async () => {
    if (!executeRecaptcha) {
      console.log("Execute recaptcha not yet available");
      return;
    }
    // ???
    await executeRecaptcha("signup");
  }, [executeRecaptcha]);

  // You can use useEffect to trigger the verification as soon as the component being loaded
  useEffect(() => {
    handleReCaptchaVerify();
  }, [handleReCaptchaVerify]);

  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validate={(value) => {
        let errors: Error = {};

        if (!value.email) {
          errors.email = "This field is required";
        } else {
          if (validateEmail(value.email))
            errors.email = "Please, enter a valid email";
        }

        return errors;
      }}
      onSubmit={async (
        values: Values,
        { setSubmitting }: FormikHelpers<Values>
      ) => {
        if (!executeRecaptcha) {
          console.log("Execute recaptcha not yet available");
          return;
        }

        const token = await executeRecaptcha("yourAction");

        // Whats happend if we don't have the token?
        if (!token) return;

        let body = {
          email: values.email,
          token,
        };

        const { status, message } = await fetchForgotPassword(body);

        if (status === "Success") {
          setForgotPassword({ show: false, email: values.email, msg: message });
        } else {
          setMsgErr(message);
        }

        setSubmitting(false);
      }}
    >
      {({ isSubmitting }) => (
        <Form className="form-formik">
          <Label htmlFor="email">
            <FontAwesomeIcon
              id="PinTooltip"
              icon={faEnvelope}
              color="#344675"
            />{" "}
            Email
          </Label>
          <FormGroup>
            <Field
              id="email"
              name="email"
              type="email"
              placeholder="Your email"
              className="form-control"
            />
          </FormGroup>
          <ErrorMessage name="email" component={CustomErrorField} />
          <Button
            className="btn-fill btn-forgot"
            color="primary"
            type="submit"
            disabled={isSubmitting}
          >
            Recover password
          </Button>
          <div className="text-center top10">
            <p>{msgErr}</p>
          </div>
        </Form>
      )}
    </Formik>
  );
};
